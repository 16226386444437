import en from "./i18n.en.json";
import de from "./i18n.de.json";
import fr from "./i18n.fr.json";
import es from "./i18n.es.json";
import cn from "./i18n.cn.json";
import zhCN from "./i18n.zh-cn.json";
import pl from "./i18n.pl.json";
import id from "./i18n.id.json";
import cz from "./i18n.cz.json";
import ptBR from "./i18n.pt-br.json";
import uk from "./i18n.uk.json";
import nl from "./i18n.nl.json";
import no from "./i18n.no.json";
import ja from "./i18n.ja.json";
import vi from "./i18n.vi.json";
import it from "./i18n.it.json";
import el from "./i18n.el.json";
import hu from "./i18n.hu.json";
import ko from "./i18n.ko.json";
import th from "./i18n.th.json";
import ru from "./i18n.ru.json";

const messages = {
    en: en,
    de: de,
    fr: fr,
    es: es,
    cn: cn,
    'zh-cn': zhCN,
    pl: pl,
    id: id,
    cz: cz,
    'pt-br': ptBR,
    uk: uk,
    nl: nl,
    no: no,
    ja: ja,
    vi: vi,
    it: it,
    el: el,
    hu: hu,
    ko: ko,
    th: th,
    ru: ru,
}

export default messages;