<template>
    <div class="app" id="app" :style="product_css" :class="['app-' + $route.name, {'dev-mode': product_data.product_settings.general.dev_mode}]">

        <div class="header" ref="header" v-if="$route.meta.header === true">
            <app-header/>
        </div>
        
        <div class="main">
            <transition :name="transitionName" v-on:before-enter="before_enter" v-on:afterEnter="after_enter">
                <router-view class="child-view"></router-view>
            </transition>
        </div>

        <div class="footer" v-if="$route.meta.footer === true">
            <app-footer/>
        </div>
        
        <login/>

        <notification/>

    </div>
</template>

<script>
import WidgetSync from '@/common/widget-sync.js';
import Settings from '@/common/general-settings.js';
import { API_URL } from "@/common/config";

export default {

    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {

        return {
            transitionName: 'slide-right',
            user_data_loaded: false,
        }

    },


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        product_data() {
            return this.$store.getters.product_data;
        },

        product_css() {
            return {
                '--brand-color':            this.$store.getters.product_data.product_settings.widget.brand_color,
                '--txt-color-head':         this.$store.getters.product_data.product_settings.widget.brand_color_txt,
                '--highlight-color-head':   ((this.$store.getters.product_data.product_settings.widget.brand_color_hgl == 'light') ? '255,255,255' : null )
            }
        },

        widget_sync() {
            return this.$store.getters.widget_sync;
        },

    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        /**
         *  Trigger before enter view
         */
        before_enter() {

            // load view
            let current_view    = (this.$route.path).substring(1).replace("/", ".");
            // load query
            let current_query   = ((this.$route.query) ? this.$route.query : false );

            // trigger widget close in parent
            WidgetSync.trigger('navigation_before_enter', {view: current_view, query: current_query});

        },

        /**
         *  Trigger after enter view
         */
        after_enter() {

            // load view
            let current_view    = (this.$route.path).substring(1).replace("/", ".");
            // load query
            let current_query   = ((this.$route.query) ? this.$route.query : false );

            // trigger widget close in parent
            WidgetSync.trigger('navigation_after_enter', {view: current_view, query: current_query});

        },

        /**
         *  Trigger SSO after load
         */
        initial_sso() {

            // set sso token to false
            var user_data = false;

            // initial delay
            window.setTimeout(() => {

                // --- SSO => WEBSITE WIDGET
                if( Settings.get('type') !== 'standalone' ) {
                    
                    // if we have an SSO token for that user
                    if(this.widget_sync.user && this.widget_sync.user.data_token) {
                        // set user data from synced widget data
                        user_data = this.widget_sync.user;
                    }

                // --- SSO => STANDALONE PAGE / IFRAME / THIRD PARTY
                } else if( Settings.get('type') == 'standalone' ) {

                    // get native sso token query
                    var queryString = window.location.search;
                    var urlParams   = new URLSearchParams(queryString);
                    // set sso token
                    var sso_token   = ((urlParams.get('sso')) ? urlParams.get('sso') : ((window.sleek_sso_token) ? window.sleek_sso_token : false ));
                    // check if we have an sso token
                    if( sso_token ) {
                        // set token on user_data object
                        user_data = {data_token: sso_token};
                        // check if we can remove the url
                        if( window.location && window.location.pathname && typeof history.pushState === 'function' ) {
                            // remove the token from the url
                            urlParams.delete('sso'); 
                            // build clean URL
                            var clean_url = window.location.pathname + ((urlParams && urlParams.toString().length > 0) ? '?' + urlParams.toString() : '');
                            // silent set clean URL
                            history.pushState( {}, null, clean_url );
                        }
                    }

                }
                
                // if we have user data
                if( user_data !== false ) {

                    // authenticate user instantly
                    this.$store.dispatch( 'widget_user_auth', user_data).then(() => {
                        // privacy notice
                        this.private_board_notice();
                    })
                    .catch(e => {
                        // privacy notice
                        this.private_board_notice();
                    });

                // if we have no user data
                } else {

                    // privacy notice
                    this.private_board_notice();

                }

            }, 500);

        },

        /**
         * INITIAL => Pageview analytics
         */
        analytics( key, key_id, type = false ) {

            // set untracked pages
            var untracked = ['inactive', '404'];

            // check if is bot
            var is_bot = /bot|googlebot|crawler|spider|robot|crawling|prerender/i.test(window.navigator.userAgent);

            // dont track pageviews for bots
            if( is_bot ) {
                // stop
                return false;
            }

            // check if page is untracked
            if( untracked.includes(key) ) {
                // stop
                return false;
            }

            // set user data
            var user_data   = this.$store.getters.user_data;
            var user_id     = false;

            // set type
            var type        = ((Settings.get('type') !== 'standalone') ? 'widget' : 'website');

            // check if we have user data
            if( user_data && user_data.user_id && typeof user_data.user_id === 'number' ) {
                // set user id
                user_id = user_data.user_id;
            }

            // set url
            let url = API_URL + 'feedback/' + this.product_data.ID + '/collect?page_key=' + key + ((key_id !== false) ? '&page_id=' + key_id : '') + ((user_id !== false) ? '&user_id=' + user_id : '') + ((type !== false) ? '&type=' + type : '');

            // INIT
            var name = "_jsonp_" + Math.floor(Math.random() * Math.floor(2000));;
            if (url.match(/\?/)) url += "&callback="+name;
            else url += "?callback="+name;
            
            // Create script
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;
            
            // Setup handler
            window[name] = function(data){
                document.getElementsByTagName('head')[0].removeChild(script);
                script = null;
                delete window[name];
            };
            
            // Load JSON
            document.getElementsByTagName('head')[0].appendChild(script);

            // stop
            return true;
            
        },

        /**
         * INITIAL => Private Board notice
         */
        private_board_notice() {

            // if board is private and user is not autheticated
            if( this.product_data.product_settings.general.private === true && ! this.$store.getters.user_data && Settings.get('type') !== 'standalone' ) {
                // Authentication error
                this.$store.dispatch( 'notification_add', { name: 'access_error', type: 'error', message: 'Access denied, this board is private and you are not authenticated via single sign-on!' } );
            }

        },

        /**
         * INITIAL => User data -> Segments
         */
        user_data_segments() {

            // make sure we dont load it twice
            if(this.user_data_loaded === true)
                return;
            this.user_data_loaded = true;

            // load user segments
            this.$store.dispatch( 'widget_user_segment' ).catch(e => {});

        },

    },


    /** ----------------------------------------------------
     * View => On Mount
     ----------------------------------------------------- */
    mounted() {

        // call initial SSO automatically
        this.initial_sso();
        
        // add theme class to body
        document.body.classList.add(this.product_data.product_settings.widget.theme);

        // trigger widget close in parent
        WidgetSync.trigger('widget_init');

        // trigger enter instantly
        this.before_enter();
        this.after_enter();

        // add page class to body
        document.body.classList.add( ('body-' + this.$route.name) );

        // add popup class
        if( this.$route.name && this.$route.name.includes('popup') )
            document.body.classList.add( 'body-popup' );

        // analytics
        this.analytics(this.$route.name, ((this.$route.params && this.$route.params.id) ? this.$route.params.id : false ));

        // load user segments
        this.user_data_segments();

    },


    /** ----------------------------------------------------
     * View => Before Mount
     ----------------------------------------------------- */
    beforeMount() {
        
        // initially load language
        this.$i18n.locale = ((this.product_data.product_settings.widget.language) ? this.product_data.product_settings.widget.language : 'en');

    },
    

    /** ----------------------------------------------------
     * Watch => route
     ----------------------------------------------------- */
    watch: {
        '$route' (to, from) {

            // remove old page class from body
            document.body.classList.remove( ('body-' + from.name) );
            
            // add page class to body
            document.body.classList.add( ('body-' + to.name) );

            // remove popup class
            if( from.name && from.name.includes('popup') )
                document.body.classList.remove( 'body-popup' );
            
            // add popup class
            if( to.name && to.name.includes('popup') )
                document.body.classList.add( 'body-popup' );

            // analytics
            this.analytics(to.name, ((to.params && to.params.id) ? to.params.id : false ));
           
            // check if we do transition
            if( 
                (this.widget_sync.skip_transition && this.widget_sync.skip_transition === true) || 
                to.meta.transition === false || 
                from.meta.transition === false 
            ) {
                // set no transition
                this.transitionName = 'no-transition';
                // trigger enter instantly
                this.before_enter();
                this.after_enter();
                // done
                return;
            }

            // set transition
            this.transitionName = (from.meta.is_back === true) ? 'slide-right' : 'slide-left';

            // set isback to false
            from.meta.is_back = false;

        }
    }

}
</script>