import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"
import ApiService from './common/general-api'
import VueI18n from 'vue-i18n'
import VueHead from 'vue-head'

// Sentry: import bug tracking
import * as Sentry from '@sentry/vue'

// import global components
import app_header from '@/components/module.glob.header.vue'
import app_footer from '@/components/module.glob.footer.vue'
import loader from '@/components/module.glob.loader.vue'
import login from '@/components/module.glob.login.vue'
import notification from '@/components/module.glob.notification.vue'

// import translations
import messages from '@/i18n/messages'

// load global components
Vue.component('app-header', app_header);
Vue.component('app-footer', app_footer);
Vue.component('loader', loader);
Vue.component('login', login);
Vue.component('notification', notification);

// user translation
Vue.use(VueI18n);

// load header
Vue.use(VueHead);

// Vue settings
Vue.config.ignoredElements = ['ion-icon'];

// load active product if we are not on production
if( process.env.NODE_ENV !== 'development' ) {
    // Sentry make availible globally => this.$sentry
    Vue.prototype.$sentry = Sentry;
    try {
    // Sentry: do bug tracking
    Sentry.init({
        Vue,
        dsn: "https://76d14d2959821ec291314428352f69c2@ingest.sleekplan.com/5",
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", "api-client.sleekplan.com", /^\//],
            }),
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: true,
                maskAllInputs: true,
                blockAllMedia: true
            }),
        ],
        environment: process.env.NODE_ENV,
        release: 'widget-frontend@' + process.env.VUE_APP_VERSION,
        autoSessionTracking: true,
        sampleRate: 0.2,
        // ! Recommend adjusting this value in production => tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring
        tracesSampleRate: 0.1,
        // Capture Replay for XX% of all sessions,
        // plus for XX% of sessions with an error
        replaysSessionSampleRate: ((window.self !== window.top) ? 0.0001 : 0.1),
        replaysOnErrorSampleRate: ((window.self !== window.top) ? 0.001 : 0.1),
        // set initial scope
        initialScope: {
            tags: { 'product_id': (( store && store.getters && store.getters.product_data ) ? store.getters.product_data.ID : null ) },
            user: { 
                id: (( store && store.getters && store.getters.user_data ) ? store.getters.user_data.user_id : null)
            },
        },
        // modify event data
        beforeSend(event) {
            if(event.request && event.request.headers && event.request.headers.Referer) {
                if(window.self !== window.top) { delete event.request.headers.Referer; }
            }
            if(event.request && event.request.url) {
                if(window.self !== window.top) { event.request.url = window.location.hash.replace('#', 'https://widget.sleekplan.app'); }
            }
            return event;
        },
    });
    } catch (error) {}
    // production tips
    Vue.config.productionTip = false;
    // production devtools
    Vue.config.devtools = false;
    // hide errors
    Vue.config.errorHandler = function() {}
    // hide warnings
    Vue.config.warnHandler = function() {}
    // hide console
    console.log     = function () {};
    console.info    = function () {};
    console.error   = function () {};
}

// Set global event bus
Vue.prototype.$eventHub = new Vue();
  
// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: 'en', // set default locale
    fallbackLocale: 'en',
    messages, // set locale messages
});

// init 
ApiService.init();

// initialisize Vue => use vm for external access
window.init_app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
});

window.mount_app = function() {
    // mount the app
    window.init_app.$mount('#app');
    // load icons
    load_icons();
    // load addtionals
    load_additionals();
    // return
    return true;
}

// load system assets
require('./assets/css/system-style.css');
require('./assets/css/system-components.css');
require('./assets/css/system-elements.css');

// load widget assets
require('./assets/css/widget-style.css');
require('./assets/css/widget-components.css');
require('./assets/css/widget-elements.css');
require('./assets/css/widget-responsive.css');

// load icons
function load_icons() {

    // load module script
    var fileref_module = document.createElement('script');
        fileref_module.setAttribute('type','module');
        fileref_module.setAttribute('src', 'https://storage.sleekplan.com/static/ionicons/dist/ionicons/ionicons.esm.js');

    // load nomodule script
    var fileref_nomodule = document.createElement('script');
        fileref_nomodule.setAttribute('nomodule','');
        fileref_nomodule.setAttribute('src', 'https://storage.sleekplan.com/static/ionicons/dist/ionicons/ionicons.js');

    // add script to head
    document.getElementsByTagName("head")[0].appendChild(fileref_module);
    document.getElementsByTagName("head")[0].appendChild(fileref_nomodule);

}

// load additionals
function load_additionals() {
    
    try {
    // set sentry product_id
    Sentry.setTag('product_id', (( store && store.getters && store.getters.product_data ) ? store.getters.product_data.ID : null ));
    } catch (error) {}

}

// --------------- for DEVELOPMENT ---------------
// load active product if we are not on production
if( process.env.NODE_ENV !== 'production' ) {

    // set url
    let url = 'http://localhost:8888/public/v1/feedback/1/widget';

    // build callback data
    var callbackName = 'jsonp_callback_' + Math.round( 100000 * Math.random() );
    window[callbackName] = function(req_data) {

        delete window[callbackName];
        document.body.removeChild(script);
        
        // set data
        window.init_app.$store.commit('PRODUCT_SET_ACTIVE', req_data.data.settings ); 
        window.init_app.$store.commit('PRODUCT_SET_SETTINGS', req_data.data.settings.product_settings);
        window.mount_app();

    };

    // create script for callback
    var script = document.createElement('script');
    script.src = url + (url.indexOf('?') >= 0 ? '&' : '?') + 'callback=' + callbackName;
    document.body.appendChild(script);

}